<!--  -->
import { json } from 'stream/consumers';
<template>
    <div class="shouhome" id="topAnchor" ref="faultTree">

      <div class="headBox">
          <div class="shouhome-header">
              <div class="shouhome-header-box">
              <div class="shouhome-header-left">
                  <img src="../assets/some/logo2.png" alt="">
                  <div>JUNKO NEW ENERGY</div>
              </div>
              <div class="shouhome-header-center"></div>
              <div class="shouhome-header-newright">
                  <div class="shouhome-header-right" @click="btnFlagFun()">
                  <div :class="btnFlag == true?'menulineactive':'middleline'"></div> 
                  </div>
              </div>

              </div>
              <div class="shouhome-header-line" :class="{ 'shouhomeDownBox': btnFlag }">
              <div class="shouhome-header-line-left" :class="item.status == true ? 'newBackColor':''"
              v-for="item in navList" :key="item.id" @click="selectFun(item)">
                  <div class="shouhome-header-line-left-box">
                      {{item.name}}
                  <!-- <van-icon name="arrow" v-if="item.childList.length > 0 && item.status == false"></van-icon>
                  <van-icon name="arrow-down" v-if="item.childList.length > 0 && item.status == true"></van-icon> -->
                  </div>
                  <!-- <template v-if="item.childList.length > 0 && item.status == true">
                    <div v-for="it in item.childList" :key="it.id" @click.stop="childselectFun(item,it)"
                    class="shouhome-header-line-left-box-child"
                    :class="it.status == true ? 'newBackColor':''">{{it.name}}</div>
                  </template> -->
              </div>

              </div>
          </div>
      </div>

      <!-- // 轮播图 -->
      <div class="shouhome-content">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="shouhome-content-swiperImg"  src="../assets/some/5.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img class="shouhome-content-swiperImg"  src="../assets/some/8.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img class="shouhome-content-swiperImg"  src="../assets/some/9.jpg" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <!-- 产品展示 -->
      <div class="shouhome-footer">
        <div class="shouhome-footer-box">Equipment</div>
        <div class="shouhome-footer-line">
          <div  v-for="item in tableList" :key="item.id" class="shouhome-footer-line-left">
            <img :src="item.imgsUrl[0]" alt="">
            <div class="shouhome-footer-line-left-title">{{item.title}}</div>
            <!-- <div class="shouhome-footer-line-left-text">{{item.productText}}</div> -->
            <div class="shouhome-footer-line-left-btn">See more <i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
      </div>

      <!-- SIC展示 -->
      <div class="shouhome-footer">
        <div class="shouhome-footer-box">Materials</div>
        <div class="shouhome-footer-materials" @click="navSicDetailFun()">
          <!-- <div class="shouhome-footer-materials-left">
            <div class="shouhome-footer-materials-left-title">High-purity Conductive SiC Powder</div>
            <div class="shouhome-footer-materials-left-text">
              <strong> · </strong>Third generation semiconductor materials with superior perfor-mance
              <br/>
              <strong> · </strong>Purity reached 99.99999%
              <br/>
              <strong> · </strong>Including needle-like material and granular material
              <br/>
              <strong> · </strong>By Chemical Vapor Deposition (CVD), SiC powder is produced by decomposition reaction of silicon source and carbon source gas at high temperature. The obtained SiC powder has high purity and the granularity is uniform.
            </div>
            <div class="shouhome-footer-line-left-btn">See more <i class="el-icon-arrow-right"></i></div>
          </div> -->
          <img class="shouhome-footer-materials-right" src="../assets/shouji/sic.jpg"/>
          <div> High-purity Conductive SiC Powder </div>
        </div>
      </div>


      <!-- 案例展示 -->
      <div class="shouhome-footer newcolor">
        <div class="shouhome-footer-box newApplication"> | Application case </div>
        <div class="shouhome-footer-line">
          <div v-for="item in newtableList" :key="item.id" class="shouhome-footer-line-left" @click="navAnDetailFun(item)">
            <img :src="item.img" alt="">
            <div class="shouhome-footer-line-left-title">{{item.title}}</div>
          </div>
        </div>
      </div>

      <!-- 合作展示 -->
      <div class="shouhome-footer">
        <div class="shouhome-footer-box newApplication"> | Partners </div>
        <div class="partners">
          <!-- <img src="../assets/some/13.png"/> -->
          <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
              <img class="shouhome-content-swiperImg"  src="../assets/shouji/h2.png" />
            </van-swipe-item>
            <van-swipe-item>
              <img class="shouhome-content-swiperImg"  src="../assets/shouji/h3.png" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <div class="shouhome-footer">
        <div class="shouhome-footer-box newApplication"> | About us </div>
        <div class="shouhome-footer-about">
          <img src="../assets/shouji/h1.jpg"/>
          <div class="shouhome-footer-about-text">
            <div class="shouhome-footer-about-text-font">Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency, focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.</div>
          </div>
        </div>
      </div>


      <div class="shouhome-footer">
        <div class="shouhome-footer-box newApplication"> | Request for Quotation </div>
        <div class="shouhome-footer-message">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="84px" class="demo-ruleForm">
              <el-form-item label="Name" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="Mobile" prop="phone">
                  <el-input v-model="ruleForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="email">
                  <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
              <el-form-item label="Country" prop="country">
                  <el-input v-model="ruleForm.country"></el-input>
              </el-form-item>
              <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                  <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
              </el-form-item>
          </el-form>
        </div>
      </div>


      
      <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import { APILogin } from "../api/APILogin";
  import { Swipe, SwipeItem,Dialog } from 'vant'
  import Bottom from "../components/shoubottom";

  export default {
    name: "shouhome",
    components: {
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
      Bottom,
    },
    data() {
      return {
        btnFlag: false,
        navList: [
            {
                id: 1,
                name: 'Home',
                status: true,
                url: require('@/assets/shouji/1.png'),
                urls: require('@/assets/shouji/2.png'),
                childList: [],
                path:'/shouhome',
            },
            {
                id: 2,
                name: 'About Us',
                status: false,
                url: require('@/assets/shouji/3.png'),
                urls: require('@/assets/shouji/4.png'),
                childList: [],
                path:'/shouAbout',
            },
            {
                id: 3,
                name: 'Product Center',
                status: false,
                url: require('@/assets/shouji/5.png'),
                urls: require('@/assets/shouji/6.png'),
                path:'/shouChan',
                childList: [
                    {
                        id: 31,
                        name: 'Equipment',
                        status: true,
                        path:'/shouChan',
                    },{
                        id: 32,
                        name: 'SIC',
                        status: false,
                        path:'/shouChan',
                    }
                ],
            }, {
                id: 4,
                name: 'Application Cases',
                status: false,
                url: require('@/assets/shouji/7.png'),
                urls: require('@/assets/shouji/8.png'),
                childList: [],
                path:'/shouYing',
            },{
                id: 5,
                name: 'Contact Us',
                status: false,
                url: require('@/assets/shouji/9.png'),
                urls: require('@/assets/shouji/10.png'),
                childList: [],
                path:'/shouContact',
            },
        ],


        tableList: [],

        newtableList: [],
        ruleForm: {
          name: '',
          country: '',
          email: '',
          message: '',
          phone: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],
          message: [
            { required: true, message: '请输入留言', trigger: 'blur' },
          ],
        },
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    filters: {},
    //方法集合
    methods: {
      // 菜单折叠
      btnFlagFun() {
          this.btnFlag = !this.btnFlag
      },
      // 选择菜单
      selectFun(item) {
          //console.log('111>>'+ JSON.stringify(item))
          this.navList.map(it => {
              if (it.id == item.id) {
                  it.status = true
                  this.$router.push(it.path)
              }else{
                  it.status = false
              }
          })
      },
      // 子选择菜单
      childselectFun(val,obj) {
          //console.log('222>>'+ JSON.stringify(val))
          //console.log('333>>'+ JSON.stringify(obj))
          this.navList.map(item => {
              if (item.id == val.id && item.childList.length > 0) {
                  item.status = true
                  item.childList.map(it=>{
                      if(it.id == obj.id) {
                          it.status = true
                          this.$router.push(it.path)
                          if(it.id == '31') {
                            this.$router.push({
                              path: '/shouChan',
                              query: {
                                state: 1,
                              }
                            })
                          }else if(it.id == '32'){
                            this.$router.push({
                              path: '/shouChan',
                              query: {
                                state: 2,
                              }
                            })
                          }
                      }else {
                          it.status = false
                      }
                  })
              }else{
                  item.status = false
              }
          })
      },

      // 提交留言
      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            // 验证通过，提交表单
            //console.log('提交的数据：', this.ruleForm);
            APILogin.messageAdd({
                name: this.ruleForm.name, 
                country: this.ruleForm.country,
                email: this.ruleForm.email, 
                message: this.ruleForm.message,
                phone: this.ruleForm.phone, 
            }).then(res => {
                Dialog.alert({
                    message: 'Thank you for your message. Someone will contact you later',
                })
                console.log("res>>>>" + JSON.stringify(res.data))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
          } else {
            // 验证失败
            console.log('验证失败！');
            return false;
          }
        });
      },


      // 获取产品列表数据
      getDataList() {
        APILogin.getChanList({
            page: 1, // 分页参几页
            limit: 500000, // 分页参每页几条
            categoryId: '', // 分类id
        }).then(res => {
            // let b = res.data.data
            // b.map(item=>{
            //   item.imgsUrl = item.imgs.split(',')
            // })
            // let newtableListArr = b.slice(-4)    
            // this.tableList = newtableListArr.reverse()

            let c = res.data.data.filter(item => item.top == '1')
            c.map(item=>{
              item.imgsUrl = item.imgs.split(',')
            })
            this.tableList = c.splice(-4)
          }).catch(fail => {
            console.log("fail>>>>" + JSON.stringify(fail))
        })
      },
      // 获取案例列表数据
      newgetDataList() {
        APILogin.getAnliChanList({
            page: this.currentPage, // 分页参几页
            limit: 500000, // 分页参每页几条
            categoryId: '', // 分类id
        }).then(res => {
          let c = res.data.data.filter(item => item.top == '1')
          let newtableListArr = c .slice(-6) 
            this.newtableList = newtableListArr.reverse()
            // console.log("11111>>>>" + JSON.stringify(this.tableList))
        }).catch(fail => {
            console.log("fail>>>>" + JSON.stringify(fail))
        })
      },

      // 跳转案例详情
      navAnDetailFun(item) {
        this.$router.push({path: './shouYingDetails', query: {id: item.id}})
      },
      // 跳转进sic详情
      navSicDetailFun() {
        this.$router.push({path: './shouChanDetails', query: {
          id: '3bd4a8cd51c084d2284dd307e1171c71',
          categoryIdVal: '21',
        }})
      },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = "JUNKO NEW ENERGY"

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
      this.getDataList()
      this.newgetDataList()
    },
    destroyed () {
    },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less">
    .van-divider {
        line-height: 14px !important;
        margin: 6px auto !important;
    }
    .el-button--primary {
      width: 100%
    }
    .ceshidiv {
      .el-form-item__label {
        line-height: 16px;
        margin-top: 10px;
      }
    }
  </style>
  
  <style lang="less" scoped src="@/styles/phone/shouhome.less"></style>
  
  
  
  